import { createSelectorHook, useDispatch } from 'react-redux';
import application from '@activebrands/core-web/state/application/reducer';
import { ApplicationActionTypes, ApplicationState } from '@activebrands/core-web/state/application/types';
import auth from '@activebrands/core-web/state/auth/reducer';
import basket from '@activebrands/core-web/state/basket/reducer';
import { BasketActionTypes, BasketState } from '@activebrands/core-web/state/basket/types';
import campaignSite from '@activebrands/core-web/state/campaignSite/reducer';
import { CampaignSiteActionTypes, CampaignSiteState } from '@activebrands/core-web/state/campaignSite/types';
import ecommerce from '@activebrands/core-web/state/ecommerce/reducer';
import { EcommerceActionTypes, EcommerceState } from '@activebrands/core-web/state/ecommerce/types';
import features from '@activebrands/core-web/state/features/reducer';
import { FeaturesActionTypes, FeaturesState } from '@activebrands/core-web/state/features/types';
import product from '@activebrands/core-web/state/product/reducer';
import { ProductActionTypes, ProductBaseState } from '@activebrands/core-web/state/product/types';
import productList from '@activebrands/core-web/state/productList/reducer';
import { ProductListActionTypes, ProductListBaseState } from '@activebrands/core-web/state/productList/types';
import recentlyViewedList from '@activebrands/core-web/state/recentlyViewed/reducer';
import { RecentlyViewedActionTypes, RecentlyViewedState } from '@activebrands/core-web/state/recentlyViewed/types';
import teamStore from '@activebrands/core-web/state/teamStore/reducer';
import user from '@activebrands/core-web/state/user/reducer';
import { UserActionTypes, UserState } from '@activebrands/core-web/state/user/types';
import { AuthActionTypes, AuthState } from '@activebrands/core-web/types/auth';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';

const combinedReducers = combineReducers({
    application,
    auth,
    basket,
    campaignSite,
    ecommerce,
    features,
    product,
    productList,
    recentlyViewedList,
    teamStore,
    user,
});

const store = createStore(combinedReducers, {}, composeWithDevTools(applyMiddleware(thunk)));

export default store;

export type StoreState = {
    application: ApplicationState;
    auth: AuthState;
    basket: BasketState;
    campaignSite: CampaignSiteState;
    ecommerce: EcommerceState;
    features: FeaturesState;
    product: ProductBaseState;
    productList: ProductListBaseState;
    recentlyViewedList: RecentlyViewedState;
    teamStore: any;
    user: UserState;
};

export type StoreActionTypes =
    | ApplicationActionTypes
    | AuthActionTypes
    | BasketActionTypes
    | CampaignSiteActionTypes
    | EcommerceActionTypes
    | FeaturesActionTypes
    | ProductActionTypes
    | ProductListActionTypes
    | RecentlyViewedActionTypes
    | UserActionTypes;

interface ExtraThunkArg {
    type?: string;
    payload?: any;
}

// ThunkAction is a type provided by redux-thunk
export type StoreAction<ReturnType = void> = ThunkAction<ReturnType, StoreState, ExtraThunkArg, StoreActionTypes>;
export type StoreDispatch = typeof store.dispatch;

export const useTypedSelector = createSelectorHook<StoreState>();
export const useTypedDispatch = () => useDispatch<StoreDispatch>();
