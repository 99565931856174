import { ValidBreakpointLabels } from 'config/breakpoints';
import {
    ImageBackground,
    RawBynderMediaData,
    VideoBackground,
    VideoSrcObject,
} from '@activebrands/core-web/libs/storyblok/storyblok';

/**
 * Accepts raw image data from Storyblok.
 */

// Map bynder derivative names to the keys we want to use
const videoDerivativeKeys = {
    'mp4': 'original',
    'MobileMP4': 'standardMobile',
    'MobileWEBM': 'modernMobile',
    'DesktopMP4': 'standardDesktop',
    'DesktopWEBM': 'modernDesktop',
};

// Use this map to target correct src for the current breakpoint
export const derivativeBreakpointMap: Record<ValidBreakpointLabels, string> = {
    'mobile.sm': 'Mobile',
    'mobile.md': 'Mobile',
    'mobile.lg': 'Mobile',
    'tablet.sm': 'Mobile',
    'tablet.lg': 'Desktop',
    'desktop.sm': 'Desktop',
    'desktop.md': 'Desktop',
    'desktop.lg': 'Desktop',
};

type BynderParamsForGenerateSrc = {
    auto: string;
    focusPointX?: number;
    focusPointY?: number;
    gravity?: string;
    maxHeight?: number;
    maxWidth?: number;
    quality: number;
    ratio?: number;
    width: number;
};

type BynderParamsForGenerateSrcData = {
    'auto'?: string;
    'fp-x'?: number;
    'fp-y'?: number;
    'gravity'?: string;
    'h'?: number;
    'q'?: number;
    'w'?: number;
};

/**
 * Adds Bynder parameters for generating source.
 * @param {Object} data - Data object containing parameters.
 * @param {number} width - Width value used in calculations.
 * @returns {Object} - Parameters object.
 */
export const addBynderParamsForGenerateSrc = ({
    auto,
    focusPointX,
    focusPointY,
    gravity,
    maxHeight,
    maxWidth,
    quality,
    ratio,
    width,
}: BynderParamsForGenerateSrc): BynderParamsForGenerateSrcData => {
    const params: BynderParamsForGenerateSrcData = {};

    if (auto) params.auto = auto;
    if (focusPointX !== undefined && focusPointY !== undefined) {
        params['fp-x'] = focusPointX;
        params['fp-y'] = focusPointY;
    }
    if (gravity) params.gravity = gravity;
    if (quality) params.q = quality;
    if (ratio) params.h = Math.floor(ratio * width);
    if (width) params.w = width;

    // Make sure that the image does not exceed the maximum width and height
    if (params.w && maxWidth && params.w > maxWidth) {
        params.w = maxWidth;
        if (ratio) {
            params.h = Math.floor(maxWidth * ratio);
        }
    }
    if (params.h && maxHeight && params.h > maxHeight) {
        params.h = maxHeight;
        if (ratio) {
            params.w = Math.floor(maxHeight / ratio);
        }
    }

    return params;
};

export type CustomTransformationData = {
    focusPointX?: number;
    focusPointY?: number;
    gravity?: string;
    ratio?: number;
};

/**
 * Extracts custom transformation data from a URL. Used in addBynderParamsForGenerateSrc
 * @param {string} [url] - The URL containing transformation data.
 * @returns {Object} - Extracted transformation data.
 */

const addCustomTransformationData = (url: string): CustomTransformationData => {
    let data = {};

    if (!url) {
        return data;
    }

    const transformRegex = /transform:([^&]+)/;
    const transformMatch = url.match(transformRegex);

    if (transformMatch) {
        const transformParams = transformMatch[1];

        if (transformParams.startsWith('fill')) {
            const widthRegex = /width:(\d+)/;
            const heightRegex = /height:(\d+)/;
            const gravityRegex = /gravity:([^,]+)/;
            const focusPointRegex = /focuspoint=([^&]+)/;

            const widthMatch = transformParams.match(widthRegex);
            const heightMatch = transformParams.match(heightRegex);
            if (widthMatch && heightMatch) {
                const width = parseInt(widthMatch[1], 10);
                const height = parseInt(heightMatch[1], 10);
                data = { ...data, ratio: height / width };
            }

            const gravityMatch = transformParams.match(gravityRegex);
            if (gravityMatch) {
                const gravity = gravityMatch[1];
                data = { ...data, gravity };
            }

            const focusPointMatch = url.match(focusPointRegex);
            if (focusPointMatch) {
                const focusPointValues = focusPointMatch[1].split(',').map(parseFloat);
                const [x, y] = focusPointValues;
                data = { ...data, focusPointX: x, focusPointY: y };
            }
        }
    }

    return data;
};

export default (
    rawBynderData: RawBynderMediaData,
    targetBreakpoint: ValidBreakpointLabels
): ImageBackground | VideoBackground | undefined => {
    const item = rawBynderData.asset.item || {};

    if (item.type === 'IMAGE') {
        return {
            alt: '',
            hostedBy: 'bynder',
            position: rawBynderData?.position,
            size: rawBynderData?.size,
            src: item.files.transformBaseUrl.url,
            title: item.name,
            type: 'image',
            width: item.originalSize?.width,
            height: item.originalSize?.height,
            ...addCustomTransformationData(item.selectedFile?.url),
        };
    }

    if (item.type === 'VIDEO') {
        // Regex that finds the derivative name of the supplied soruces
        // match: https://dam.activebrands.com/asset/45825223-9b97-4d3a-828b-8ec880617d2c/DesktopWEBM/Durden-RIG-Reflect-Goggles-x-Jackson-Hole.webm
        // match: https://dam.activebrands.com/m/46c8045609a202a5/MobileWEBM-Connor_RIG-Tech_With-Titles_1x1.webm
        // and retrieve DesktopWEBM && MobileWEBM etc.
        const regex = /^https:\/\/dam\.activebrands\.com\/.*?(DesktopWEBM|DesktopMP4|MobileWEBM|MobileMP4|mp4)/;

        // Loop through the sources and find the derivative name, map them to the key we want to use
        const urlMap =
            item.previewUrls?.reduce((acc, url) => {
                const videoDerivative = url.match(regex)?.at(1) as
                    | 'DesktopMP4'
                    | 'DesktopWEBM'
                    | 'MobileMP4'
                    | 'MobileWEBM'
                    | 'mp4'
                    | undefined;

                if (videoDerivative) {
                    acc[videoDerivativeKeys[videoDerivative]] = url;
                }

                return acc;
            }, {}) || {};

        const derivativeBreakpoint = derivativeBreakpointMap[targetBreakpoint];
        // BaseVideo can handle an array of sources of different types
        const src: VideoSrcObject[] = [];
        if (urlMap[`standard${derivativeBreakpoint}`]) {
            src.unshift({ src: urlMap[`standard${derivativeBreakpoint}`], type: 'video/mp4' });
        }
        // If we have a fallback added (standard), we want to prefer webm (modern) if it's possible, so we place it first in the array
        if (src.length && urlMap[`modern${derivativeBreakpoint}`]) {
            src.unshift({ src: urlMap[`modern${derivativeBreakpoint}`], type: 'video/webm' });
        }

        return {
            src: src.length ? src : item.previewUrls?.[0] || '',
            title: item.name,
            type: 'video',
            hostedBy: 'bynder',
        };
    }

    return undefined;
};
