import tracking from '@activebrands/core-web/components/tracking';
import { useEffect } from "react";
import { inServer } from "@activebrands/core-web/utils/constants";
import { Helmet } from "react-helmet";
import GTM from "@grebban/gtm";

export default () => {
    // If there is no GTM Tracking key, we should not load GTM.
    const GTM_TRACKING_KEY = process.env.REACT_APP_GTM_TRACKING_KEY;
    const GTM_ENVIRONMENT = process.env.REACT_APP_GTM_ENVIRONMENT;
    const params = {
        'gtm_auth': process.env.REACT_APP_GTM_AUTH,
        'gtm_preview': process.env.REACT_APP_GTM_PREVIEW,
        'gtm_cookies_win': 'x',
    };

    if (!GTM_TRACKING_KEY || inServer) {
        return null;
    }

    useEffect(() => {
        tracking.init();
    }, []);

    const gtmUrl = new URL('https://www.googletagmanager.com/ns.html');
    gtmUrl.searchParams.append('id', GTM_TRACKING_KEY);

    if (GTM_ENVIRONMENT !== 'live') {
        Object.keys(params).forEach(k => {
            gtmUrl.searchParams.append(k, params[k]);
        });
    }

    // Gather dependency data to pass into GTM package
    const dependencies = [{
        domainKey: process.env.REACT_APP_ONETRUST_SCRIPT_KEY || '',
        consentModeEnabled: true,
        name: 'OneTrust',
    }];

    return (
        // eslint-disable-next-line react/jsx-pascal-case
        <GTM
            dependencies={dependencies}
            helmetComponent={Helmet}
            trackingKey={GTM_TRACKING_KEY}
        />
    );
};
