import transformProductPageViewMedia from '@activebrands/core-web/utils/product/transform-product-page-view-media';

interface Props {
    isTeamStoreProduct: boolean;
    product: Record<string, any>;
    source: 'Centra' | 'GrebCommerce' | 'JSON';
}

// @todo: Type and refactor this function
const transformProductCardMiniData = ({ isTeamStoreProduct, product, source }: Props) => {
    let media = transformProductPageViewMedia({ images: product?.media, source });
    let colorName = product?.productReference?.customAttributes?.attrColor?.name || product.color;
    let colorHex = product?.productReference?.customAttributes?.attrColor?.hex;
    let colorImage = product?.productReference?.customAttributes?.attrColor?.image;
    let name = product?.productName;
    let uri = isTeamStoreProduct
        ? `${process.env.REACT_APP_TEAMSTORE_PRODUCT_PATH}?${product.url?.replace(/\/[^/]*$/, '')}`
        : product?.url;
    let salePriceWithCurrency = product?.totalPrice || '';
    let priceWithCurrency = product?.totalPriceBeforeDiscount || '';
    let currentVariant = { colorName, uri: uri, image: colorImage, hex: colorHex };
    let relatedVariations = product?.productReference?.relatedVariations?.map(variation => ({
        colorName: variation.colorName,
        uri: variation.uri,
        image: variation.image,
        hex: variation.hex,
    }));

    if (source == 'GrebCommerce') {
        media = transformProductPageViewMedia({ images: product?.media, source });
        colorName = product?.customAttributes?.attrColor?.name || product.color;
        colorHex = product?.customAttributes?.attrColor?.hex || product?.customAttributes?.attrColor?.colorHex;
        colorImage = product?.customAttributes?.attrColor?.image;
        name = product?.name;
        uri = product?.uri;
        salePriceWithCurrency = product?.price?.salePriceWithCurrency || product?.totalPrice || '';
        priceWithCurrency = product?.price?.priceWithCurrency || product.totalPriceBeforeDiscount || '';
        currentVariant = { colorName, uri: uri, image: colorImage, hex: colorHex };
        relatedVariations = product?.relatedVariations?.map(relatedVariation => ({
            colorName: relatedVariation?.colorName,
            uri: relatedVariation?.uri,
            image: relatedVariation?.image,
            colorHex: relatedVariation?.hex || relatedVariation?.colorHex,
        }));
    }

    if (source == 'JSON') {
        //Current product is always listed first in relatedVariations product when data is collected fron GC-JSON
        media = transformProductPageViewMedia({ images: product?.media, source });
        name = product?.name;
        relatedVariations = product?.relatedVariations?.map(relatedVariation => ({
            colorName: relatedVariation?.colorName || relatedVariation?.color,
            uri: relatedVariation?.uri,
            image: relatedVariation?.image,
            colorHex: relatedVariation?.hex || relatedVariation?.colorHex,
        }));
        colorName = product?.relatedVariations?.[0]?.colorName || product.color;
        colorHex = product?.relatedVariations?.[0]?.hex || product?.relatedVariations?.[0]?.colorHex;
        colorImage = product?.relatedVariations?.[0]?.attrColor?.image;
        uri = product?.uri;
        salePriceWithCurrency = product?.price?.salePriceWithCurrency || '';
        priceWithCurrency = product?.price?.priceWithCurrency || '';
        currentVariant = { colorName, uri: uri, image: colorImage, hex: colorHex };
    }

    return {
        color: colorName,
        colorHex,
        currentVariations: currentVariant,
        id: product?.id,
        line: product?.line,
        media,
        name,
        price: { priceWithCurrency, salePriceWithCurrency },
        quantity: product?.quantity,
        relatedVariations,
        size: product?.size,
        uri,
        variations: product?.variations || [],
    };
};

export default transformProductCardMiniData;
